import React, { useState, useEffect } from "react";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  listAll,
  deleteObject,
} from "firebase/storage";
import { storage, db, auth } from "../firebase";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Typography, Button, Paper, Grid, Box, Modal, IconButton } from "@mui/material";

export default function AddCarouselImage() {
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [allFieldsError, setAllFieldsError] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);

  const [formData, setFormData] = useState({
    image: "",
  });

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    fetchImageUrls();
  }, []);

  const fetchImageUrls = async () => {
    try {
      const folderRef = ref(storage, "/HomeCarousel");
      const fileList = await listAll(folderRef);

      const urls = await Promise.all(
        fileList.items.map(async (item) => {
          const downloadUrl = await getDownloadURL(item);
          return downloadUrl;
        })
      );

      setImageUrls(urls);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReturnBack = () => {
    navigate("/AdminPage");
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handlePublish = () => {
    if (!formData.image) {
      setAllFieldsError(true);
      return;
    }

    const storageRef = ref(
      storage,
      `/HomeCarousel/${Date.now()}${formData.image.name}`
    );

    const uploadImage = uploadBytesResumable(storageRef, formData.image);

    uploadImage.on(
      "state_changed",
      (snapshot) => {
        const progressPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progressPercent);
      },
      (err) => {
        console.log(err);
      },
      async () => {
        setError(false);
        setAllFieldsError(false);
        setSubmitted(true);
        setProgress(0);
        setFormData({
          image: "",
        });

        // Fetch updated image URLs after successful upload
        await fetchImageUrls();
      }
    );
  };

  const handleDelete = async (imageUrl) => {
    if (window.confirm("Opravdu smazat?")) {
      try {
        const storageRef = ref(storage, imageUrl);
        await deleteObject(storageRef);
        // Update imageUrls state after successful deletion
        setImageUrls((prevUrls) =>
          prevUrls.filter((url) => url !== imageUrl)
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div
      style={{
        marginTop: "10em",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2>Přidat položku</h2>

      <div style={{ marginBottom: "1em" }}>
        <input
          type="file"
          name="image"
          id="image"
          accept="image/*"
          style={{ width: "100%" }}
          onChange={(e) => handleImageChange(e)}
        />
        {progress === 0 ? null : (
          <div>
            <div style={{ width: `${progress}%` }}>{`uploading image ${progress}%`}</div>
          </div>
        )}
      </div>

      <div style={{ marginBottom: "2em" }}>
        <button onClick={handlePublish}>Přidat</button>
        <button onClick={handleReturnBack} style={{ marginLeft: "1em" }}>
          Zpět
        </button>
      </div>

      <div style={{ maxWidth: "400px", margin: "10px auto" }}>
        {submitted && !error && (
          <div style={{ marginBottom: "1em" }}>
            <Alert severity="success" onClose={() => setSubmitted(false)}>
              Položka přidána.
            </Alert>
          </div>
        )}

        {error && (
          <div style={{ marginBottom: "1em" }}>
            <Alert severity="error" onClose={() => setError(false)}>
              Něco se pokazilo. Zkuste prosím znovu.
            </Alert>
          </div>
        )}

        {allFieldsError && (
          <div style={{ marginBottom: "1em" }}>
            <Alert severity="error" onClose={() => setAllFieldsError(false)}>
              Vyberte soubor.
            </Alert>
          </div>
        )}
      </div>
      <div className="row mt-5">
        <div className="col-md-8">
          <div className="image-grid">
            <Box display="grid" gridTemplateColumns={{ xs: '1fr', sm: 'repeat(3, 1fr)' }} gap="1em">
                {imageUrls.map((imageUrl) => (
                    <Box sx={{display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',}}>
                        <img
                            key={imageUrl}
                            src={imageUrl}
                            alt="Project"
                            style={{ width: "300px", height: "auto"}}
                        />
                        <button onClick={() => handleDelete(imageUrl)}>Smazat</button>
                  </Box>
                ))}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
