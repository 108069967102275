import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import React, { useState } from 'react'
import Axios from 'axios'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import '../App.css';

const sendEmailURL = 'https://us-central1-jl-stavby.cloudfunctions.net/sendEmail';
    
const BazarForm = (props) => {
    const { title } = props;
  const [state, setState] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    text: '',
    amount: '',
  });
  
  const { name, company, email, phone, text, amount } = state;

  const handleState = ({ target: { id, value } }) => {
    setState({ ...state, [id]: value });
    setErrors({ ...errors, [id]: false }); // Clear the error state when input changes
  };

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    amount: false
  });

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{9}$/;
    return phoneRegex.test(phone);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Perform field validation
    let hasErrors = false;

    if (!name) {
      setErrors((prevState) => ({ ...prevState, name: true }));
      hasErrors = true;
    }

    if (!email) {
      setErrors((prevState) => ({ ...prevState, email: true }));
      hasErrors = true;
    } else if (!validateEmail(email)) {
      setErrors((prevState) => ({ ...prevState, emailFormat: true }));
      hasErrors = true;
    }

    if (phone && !validatePhoneNumber(phone)) {
      setErrors((prevState) => ({ ...prevState, phone: true }));
      hasErrors = true;
    }

    if (!amount) {
        setErrors((prevState) => ({ ...prevState, name: true }));
        hasErrors = true;
      }

    if (hasErrors) {
      return;
    }

    let message = `Jméno: ${name}`;
    if (company) {
        message += `, ${company}`;
      }
      message += `\nE-mail: ${email}\nTel: ${phone}\nPoptávka materiál:\n${title} : ${amount} ks\n${text}`;
    const subject = `${name} - Poptávka materiál`;

    Axios.get(sendEmailURL, {
      params: {
        message,
        subject,
      },
    })
      .then(() => {
        setSubmitted(true);
        setState({
          name: '',
          company: '',
          email: '',
          phone: '',
          amount: '',
          text: '',
        });
        setErrors({
          name: false,
          email: false,
          phone: false,
        });
        setError(false);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      });
  };

      return (
        <form className="contact-form" noValidate autoComplete="off" onSubmit={sendEmail}>
          <div className="row">
            <TextField
              required
              id="name"
              label="Jméno a příjmení"
              margin="normal"
              className="form-field-bazar"
              value={name}
              onChange={handleState}
              error={errors.name}
              helperText={errors.name ? 'Vyplňte toto pole' : ''}
            />
          </div>
          <div className="row">
            <TextField
              id="company"
              label="Firma"
              margin="normal"
              className="form-field-bazar"
              value={company}
              onChange={handleState}
            />
          </div>
          <div className="row">
            <TextField
              required
              type="email"
              id="email"
              label="E-mail"
              margin="normal"
              className="form-field-bazar"
              value={email}
              onChange={handleState}
              error={errors.email || (email && !validateEmail(email))}
              helperText={
                errors.email
                  ? 'Vyplňte toto pole'
                  : email && !validateEmail(email)
                  ? 'Neplatný formát'
                  : ''
              }
            />
        </div>
        <div className="row">
            <TextField
              id="phone"
              label="Telefon"
              margin="normal"
              className="form-field-bazar"
              value={phone}
              onChange={handleState}
              error={errors.phone}
              helperText={errors.phone ? 'Neplatný formát' : ''}
            />
          </div>
          <div className="row">
              <TextField
                required
                id="amount"
                label="Množství"
                margin="normal"
                type="number"
                className="form-field-bazar"
                value={amount}
                onChange={handleState}
                error={errors.name}
                helperText={errors.amount ? 'Vyplňte toto pole' : ''}
              />
            </div>
          <div className="row">
            <TextField
              id="text"
              label="Zpráva"
              margin="normal"
              multiline
              rows={5}
              className="form-field-bazar"
              value={text}
              onChange={handleState}
            />
          </div>

          <Button
            type="submit"
            variant="contained"
            sx={{
              mr: 2,
              color: 'white',
              '&:hover': {backgroundColor: '#313131',},
              backgroundColor: '#FF750E',
              borderRadius: 0,
              pl: '0.5rem',
              mt: 2,
            }}
            startIcon={<SendIcon sx={{ paddingLeft: 1, color: 'white' }} />}
          >
            ODESLAT
          </Button>
          {submitted && !error && (
            <Alert severity="success" onClose={() => setSubmitted(false)} sx={{ mt: 2 }}>
              <AlertTitle>Děkujeme za zprávu</AlertTitle>
              Co nejdříve se vám ozveme s detaily poptávky.
          </Alert>
          )}

          {error && (
            <Alert severity="error" onClose={() => setError(false)} sx={{ mt: 2 }}>
              <AlertTitle>Něco se pokazilo</AlertTitle>
              Zkuste prosím znovu.
            </Alert>
          )}
        </form>
      );
    };
    
    export default BazarForm;