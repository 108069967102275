import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import bacgroundImg from '../assets/ConstructionImg.jpg';
import dotsBackground from '../assets/dots_background.png';
import van from '../assets/Transport.jpg';
import volkswagen from '../assets/volkswagen.png';
import weight from '../assets/bar.png';
import price_tag from '../assets/price-tag.png';
import measure from '../assets/measure.png';
import Divider from '@mui/material/Divider';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ContactForm from "../components/ContactForm";

const Construction = () => {
  return (
    <Box>
      <Box sx={{
          height: { xs: '400px', sm: '500px'},
          backgroundImage: `url(${bacgroundImg})`,
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          color: 'white'
      }}>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          mt: {sm:30,xs:15}
          }}>
          <Typography sx={{ color: '#FF750E',typography: { sm: 'h2', xs: 'h4' } }}>SLUŽBY</Typography>
          <Typography sx={{ color: 'white',typography: { sm: 'h3', xs: 'h5' } }}>STAVEBNÍ PRÁCE</Typography>
        </Box>
      </Box>

      <Box sx={{ 
        backgroundImage: `url(${dotsBackground})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        pb:{sm:15,xs:5}
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center',mx:{sm:24,xs:5}, mb:{sm:6,xs:0},mt:{sm:20,xs:5}}}>
          <Typography sx={{ mx: 2,typography: { sm: 'h4', xs: 'h6' } }}>O NAŠICH STAVBÁCH</Typography>
          <Box sx={{ flexGrow: 1 }}>
            <Divider sx={{ borderColor: '#FF750E' }} />
          </Box>
        </Box>
        <Box sx={{ display:'flex', flexDirection: 'row', mx: {sm:28,xs:10}}}>
          <Box sx={{ display:'flex', flexDirection: 'column', textAlign: 'left', mr: {sm:20,xs:0}, pt: 5}}>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}}>Kvalita práce, zkušení zaměstnanci a flexibilita spolupráce </Typography>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}} gutterBottom="true">jsou základními pilíři, na kterých stojíme.</Typography>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}}> Věříme, že stavebnictví je uměním, které vyžaduje </Typography>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}} gutterBottom="true">preciznost a profesionalitu v každém detailu.</Typography>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}}>S naším týmem zkušených odborníků jsme připraveni plnit </Typography>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}}>vaše stavební projekty s maximálním nasazením a know-how.</Typography>
          </Box>
          <Box sx={{ display:{sm:'flex',xs:'none'}, pt:5}}>
            <img src={van} height={"auto"} width={"700px"} alt="Van" />
          </Box>
        </Box>
      </Box>
      <Box sx={{display:'flex', flexDirection:'column', bgcolor: "#f9f9f9", pb:{sm:12,xs:2}}}>
        <Box sx={{ display: 'flex', alignItems: 'center',mx:{sm:24,xs:5}, mt:{sm:10,xs:2}, mb:{sm:6,xs:0}}}>
          <Typography variant="h4" sx={{ mx: 2,typography:{sm:"h4",xs:"h6"} }}>VOZOVÝ PARK</Typography>
          <Box sx={{ flexGrow: 1 }}>
            <Divider sx={{ borderColor: '#FF750E' }} />
          </Box>
        </Box>

        <Box sx={{display:'flex', flexDirection:{sm:'row',xs:'column'}, gap: {sm:3,xs:0}, justifyContent:'center'}}>
          <Box sx={{flexDirection:'column', display:'flex', alignItems:'center', bgcolor:"white",my:2,px:3,pb:{sm:6,xs:2}}}>
            <img src={volkswagen} height={{sm:"300",xs:"150"}} width={{sm:"400",xs:"250"}} alt="Volkswagen" />
            <Divider sx={{ borderColor: '#FF750E', width:'75%',mb:2}} />
            <Typography color='grey' sx={{typography:{sm:'h5',xs:'subtitle2'}}}>Dodávka</Typography>
            <Typography sx={{typography:{sm:'h4',xs:'subtitle1'}}}>Volkswagen Transporter T6</Typography>
            <Box sx={{flexDirection:'column', gap:1, pt:2}}>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}} color="grey" >
                <img src={weight} height={{sm:"24",xs:"12"}} width={{sm:"24",xs:"12"}} alt="Bar" />
                Nosnost: do 2 t
              </Typography>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}} color="grey">
                <img src={measure} height={"24"} width={"24"} alt="Measure" />
                Rozměr: 4x2x2 m
              </Typography>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}} color="grey">
                <img src={price_tag} height={"24"} width={"24"} alt="Price-tag" />
                Cena: 50 Kč / km bez DPH
              </Typography>
            </Box>
          </Box>

          <Box sx={{ flexDirection:'column', display:'flex', alignItems:'center', bgcolor:"white",my:2,px:3,pb:6}}>
            <img src={volkswagen} height={{sm:"300",xs:"150"}} width={{sm:"400",xs:"250"}} alt="Volkswagen" />
            <Divider sx={{ borderColor: '#FF750E', width:'75%',mb:2}} />
            <Typography color='grey' sx={{typography:{sm:'h5',xs:'subtitle2'}}}>Dodávka</Typography>
            <Typography sx={{typography:{sm:'h4',xs:'subtitle1'}}}>Volkswagen Transporter T6</Typography>
            <Box sx={{ flexDirection:'column', gap:1, pt:2}}>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}} color="grey">
                <img src={weight} height={"24"} width={"24"} alt="Bar" />
                Nosnost: do 2 t
              </Typography>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}} color="grey">
                <img src={measure} height={"24"} width={"24"} alt="Measure" />
                Rozměr: 4x2x2 m
              </Typography>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}} color="grey">
                <img src={price_tag} height={"24"} width={"24"} alt="Price-tag" />
                Cena: 50 Kč / km bez DPH
              </Typography>
            </Box>
          </Box>

          <Box sx={{ flexDirection:'column', display:'flex', alignItems:'center', bgcolor:"white",my:2,px:3,pb:6}}>
            <img src={volkswagen} height={{sm:"300",xs:"150"}} width={{sm:"400",xs:"250"}} alt="Volkswagen" />
            <Divider sx={{ borderColor: '#FF750E', width:'75%',mb:2}} />
            <Typography color='grey' sx={{typography:{sm:'h5',xs:'subtitle2'}}}>Dodávka</Typography>
            <Typography sx={{typography:{sm:'h4',xs:'subtitle1'}}}>Volkswagen Transporter T6</Typography>
            <Box sx={{ flexDirection:'column', gap:1, pt:2}}>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}} color="grey">
                <img src={weight} height={"24"} width={"24"} alt="Bar" />
                Nosnost: do 2 t
              </Typography>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}} color="grey">
                <img src={measure} height={"24"} width={"24"} alt="Measure" />
                Rozměr: 4x2x2 m
              </Typography>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}} color="grey">
                <img src={price_tag} height={"24"} width={"24"} alt="Price-tag" />
                Cena: 50 Kč / km bez DPH
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ 
        backgroundImage: `url(${dotsBackground})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        pb:{sm:15,xs:2}
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center',mx:{sm:24,xs:5}, mt:{sm:20,xs:4}, mb:{sm:6,xs:0}}}>
            <Typography sx={{ mx: 2,typography:{sm:"h4",xs:"h6"} }}>FORMULÁŘ</Typography>
            <Box sx={{ flexGrow: 1 }}>
              <Divider sx={{ borderColor: '#FF750E' }} />
            </Box>
          </Box>
          <ContactForm />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', pb: {sm:5,xs:0}, bgcolor: "#f9f9f9" }}>
      <Box sx={{ display: 'flex', alignItems: 'center',mx:{sm:24,xs:5}, mt:{sm:20,xs:4}, mb:{sm:6,xs:0}}}>
        <Typography sx={{ mx: 2,typography:{sm:"h4",xs:"h6"} }}>KONTAKT</Typography>
          <Box sx={{ flexGrow: 1 }}>
            <Divider sx={{ borderColor: '#FF750E' }} />
          </Box>
        </Box>

        <Box sx={{ display: 'flex',flexDirection: {sm:'row',xs:'column'}, alignItems: 'left', mx: {sm:26,xs:8}, mb: {sm:6,xs:0} ,justifyContent:"space-between"}}>
          <Box sx={{ display: 'flex',flexDirection: 'column'}}>
            <Typography sx={{textAlign:'left', typography:{sm:'subtitle1',xs:'subtitle2'}}}> Máte zájem o naše služby nebo se potřebujete na něco zeptat?</Typography>
            <Typography sx={{textAlign:'left', typography:{sm:'subtitle1',xs:'subtitle2'}}}> Kontaktujte nás!</Typography>
          </Box>
          <Typography sx={{ display: 'flex', alignItems: 'center', typography:{sm:'h6',xs:'subtitle1'}}}>
            <PhoneIcon style={{ color: '#FF750E'}} />
              +420 602 541 936
          </Typography>
          <Typography sx={{ display: 'flex', alignItems: 'center', typography:{sm:'h6',xs:'subtitle1'}}}>
            <EmailIcon style={{ color: '#FF750E'}} />
              jl.stavbyadoprava@gmail.com
          </Typography>
        </Box>
      </Box>
    </Box>
  )
};
  
  export default Construction;