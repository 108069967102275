import React, { useState } from "react";
import { Timestamp, collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db, auth } from "../firebase";
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import AdminPostsList from "../components/AdminPostsList";
import { Link } from "react-router-dom";

export default function CreatePost() {
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [allFieldsError, setAllFieldsError] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    price: "",
    description: "",
    image: "",
    createdAt: Timestamp.now().toDate(),
  });

  const [progress, setProgress] = useState(0);

  
  const handleReturnBack = () => {
    navigate("/AdminPage");
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handlePublish = () => {
    if (!formData.title || !formData.description || !formData.image || !formData.price) {
      setAllFieldsError(true);
      return;
    }

    const storageRef = ref(
      storage,
      `/Bazar/${Date.now()}${formData.image.name}`
    );

    const uploadImage = uploadBytesResumable(storageRef, formData.image);

    uploadImage.on(
      "state_changed",
      (snapshot) => {
        const progressPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progressPercent);
      },
      (err) => {
        console.log(err);
      },
      () => {
        setFormData({
          title: "",
          price: "",
          description: "",
          image: "",
        });

        getDownloadURL(uploadImage.snapshot.ref).then((url) => {
          const articleRef = collection(db, "Bazar");
          addDoc(articleRef, {
            title: formData.title,
            price: formData.price,
            description: formData.description,
            imageUrl: url,
            createdAt: Timestamp.now().toDate(),
          })
            .then(() => {
              setError(false);
              setAllFieldsError(false);
              setSubmitted(true);
              setProgress(0);
            })
            .catch((err) => {
              setError(true);
            });
        });
      }
    );
  };

  return (
    <div style={{ marginTop: '10em', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2>Přidat položku</h2>
  
      <div style={{ marginBottom: '1em' }}>
        <label htmlFor="title" style={{ fontWeight: 'bold' }}>Název</label>
        <input
          type="text"
          name="title"
          id="title"
          style={{ width: '100%' }}
          value={formData.title}
          onChange={(e) => handleChange(e)}
        />
      </div>
  
      <div style={{ marginBottom: '1em' }}>
        <label htmlFor="price" style={{ fontWeight: 'bold' }}>Cena</label>
        <input
          type="text"
          name="price"
          id="price"
          style={{ width: '100%' }}
          value={formData.price}
          onChange={(e) => handleChange(e)}
        />
      </div>
  
      <div style={{ marginBottom: '1em' }}>
        <label htmlFor="description" style={{ fontWeight: 'bold' }}>Popis</label>
        <textarea
          name="description"
          id="description"
          style={{ width: '100%' }}
          value={formData.description}
          onChange={(e) => handleChange(e)}
        />
      </div>
  
      <div style={{ marginBottom: '1em' }}>
        <label htmlFor="image" style={{ fontWeight: 'bold' }}>Fotka</label>
        <input
          type="file"
          name="image"
          id="image"
          accept="image/*"
          style={{ width: '100%' }}
          onChange={(e) => handleImageChange(e)}
        />
        {progress === 0 ? null : (
          <div>
            <div style={{ width: `${progress}%` }}>{`uploading image ${progress}%`}</div>
          </div>
        )}
      </div>
  
      <div style={{ marginBottom: '2em' }}>
        <button onClick={handlePublish}>Přidat</button>
        <button onClick={handleReturnBack} style={{ marginLeft: '1em' }}>
          Zpět
        </button>
      </div>
  
      <div style={{ maxWidth: '400px', margin: '10px auto' }}>
        {submitted && !error && (
          <div style={{ marginBottom: '1em' }}>
            <Alert severity="success" onClose={() => setSubmitted(false)}>
              Položka přidána.
            </Alert>
          </div>
        )}
  
        {error && (
          <div style={{ marginBottom: '1em' }}>
            <Alert severity="error" onClose={() => setError(false)}>
              Něco se pokazilo. Zkuste prosím znovu.
            </Alert>
          </div>
        )}
  
        {allFieldsError && (
          <div style={{ marginBottom: '1em' }}>
            <Alert severity="error" onClose={() => setAllFieldsError(false)}>
              Vyplňte všechny pole.
            </Alert>
          </div>
        )}
      </div>
      <div className="row mt-5">
                <div className="col-md-8">
                  <AdminPostsList />
                </div>
              </div>
    </div>
  );
  
}