import React, { useState, useEffect } from "react";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../firebase";
import { Typography, Button, Paper, Grid, Box, Modal, IconButton } from "@mui/material";
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';

export default function PastProjectsList() {
  const [posts, setPosts] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const postRef = collection(db, "OurWork");
    const q = query(postRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const posts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(posts);
    });
  }, []);

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      {posts.length === 0 ? (
        <p>Žádné položky</p>
      ) : (
        posts.map(({ id, title, description, imageUrls }) => {
          return (
            <Paper key={id} elevation={3} sx={{ mt: 3, p: 6}}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <Grid container direction="column" alignItems="flex-start">
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Typography sx={{typography:{sm:'h4',xs:'h5'}}}>{title}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item sx={{ textAlign: "left" }}>
                      <Typography sx={{typography:{sm:'text',xs:'subtitle2'}}}>{description}</Typography>
                      <Divider sx={{ backgroundColor: '#FF750E',height: '2px', my: 3}} />
                    </Grid>
                    <Grid item>
                    <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap="1em">
                      {imageUrls.map((imageUrl) => (
                          <img
                          key={imageUrl}
                          src={imageUrl}
                          alt="Project"
                          style={{ width: "100%", height: "auto", cursor: "pointer" }}
                          onClick={() => openModal(imageUrl)}
                        />
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        );
      })
    )}

      {/* Modal */}
      <Modal open={Boolean(selectedImage)} onClose={closeModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '15%',
            left: '15%',
            right: '15%',
            bottom: '15%',
            backgroundColor: '#FFFFFF',
            padding: {sm:'3rem',xs:'0.5rem'},
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={selectedImage}
            alt="Selected Project"
            style={{ width: "100%", height: "auto", maxHeight: "100%",border: "3px solid #FF750E" }}
          />
        </Box>
      </Modal>
    </div>
  );
}