import React, { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import AdminPage from './pages/AdminPage';
import CreatePost from './components/CreatePost';
import AddPastProjects from './components/AddPastProjects';
import Login from './pages/Login';
import GDPR from './pages/GDPRPage';
import BazarItemDetail from './components/BazarItemDetail';
import AddCarouselImage from './components/AddCarouselImage';
import Footer from './components/Footer';
import { navBarRoutes, footerRoutes } from './routes';
import NavBar from './components/NavBar';
import ScrollToTop from './components/ScrollToTop';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthContextProvider } from './context/AuthContext';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

function App() {

  return (
    <ThemeProvider theme={theme}>
    <div className="App">
        <div className="navbar">
          <NavBar routes={navBarRoutes} />
          <Routes>
            {navBarRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
          </Routes>
        </div>
        <AuthContextProvider>
          <Routes>
            <Route
              path='/AdminPage'
              element={
                <ProtectedRoute>
                  <AdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/CreatePost'
              element={
                <ProtectedRoute>
                  <CreatePost />
                </ProtectedRoute>
              }
            />
            <Route
              path='/AddCarouselImage'
              element={
                <ProtectedRoute>
                  <AddCarouselImage />
                </ProtectedRoute>
              }
            />
            <Route
              path='/AddPastProjects'
              element={
                <ProtectedRoute>
                  <AddPastProjects />
                </ProtectedRoute>
              }
            />
            <Route path="/Login" element={<Login/>} />
            <Route path="/BazarItemDetail/:id" element={<BazarItemDetail/>} />
          </Routes>
        </AuthContextProvider>
          <Routes>
            <Route path="/GDPR" element={<GDPR/>} />
          </Routes>
        <div className="footer">
          <Footer>
            <Routes>
              {footerRoutes.map((route) => (
                <Route key={route.path} {...route} />
              ))}
            </Routes>
          </Footer>
        </div>

      <ScrollToTop />
    </div>
    </ThemeProvider>
  );
}

export default App;
