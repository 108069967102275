import { collection, onSnapshot, orderBy, query, deleteDoc, doc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { storage, db } from "../firebase";
import { deleteObject, ref } from "firebase/storage";
import { Typography, Button, Paper, Grid } from "@mui/material";

export default function AdminPosts() {
  const [posts, setPosts] = useState([]);

  const handleDelete = async (id, imageUrl) => {
    if (window.confirm("Opravdu smazat?")) {
      try {
        await deleteDoc(doc(db, "Bazar", id));
        //toast("Article deleted successfully", { type: "success" });
        const storageRef = ref(storage, imageUrl);
        await deleteObject(storageRef);
      } catch (error) {
        //toast("Error deleting article", { type: "error" });
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const postRef = collection(db, "Bazar");
    const q = query(postRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const posts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(posts);
      console.log(posts);
    });
  }, []);
  
  return (
    <div>
      {posts.length === 0 ? (
        <p>Žádné položky</p>
      ) : (
        posts.map(({ id, title, price, description, imageUrl, createdAt }) => {
          const formattedDate = createdAt.toDate().toDateString();
          return (
            <Paper key={id} elevation={3} sx={{ mt: 3, p: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <img
                          src={imageUrl}
                          alt={title}
                          style={{ height: 180, width: 180 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Grid container direction="column" alignItems="flex-start">
                            <Grid item container direction="row" justifyContent="space-between" alignItems="flex-start">
                                <Grid item>
                                    <Typography sx={{typography:{sm:'h4',xs:'h6'}}}>{title}</Typography>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => handleDelete(id, imageUrl)}>Smazat</Button>
                                </Grid>
                            </Grid>
                            <Grid item>
                              <Typography sx={{typography:{sm:'h5',xs:'h6'}}}>{price}</Typography>
                            </Grid>
                            <Grid item>
                              <Typography sx={{typography:{sm:'sumtitle1',xs:'subtitle2'}}}>Přidáno: {formattedDate}</Typography>
                            </Grid>
                            <Grid item>
                              <Typography sx={{typography:{sm:'text',xs:'subtitle2'}}}>{description}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
          );
        })
      )}
    </div>
  );
}