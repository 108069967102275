import React, { useState } from "react";
import { Timestamp, collection, addDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db, auth } from "../firebase";
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import PastProjectsListAdmin from "../components/PastProjectsListAdmin";

export default function CreatePost() {
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [allFieldsError, setAllFieldsError] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    images: [],
    createdAt: Timestamp.now().toDate(),
  });

  const [progress, setProgress] = useState(0);

  
  const handleReturnBack = () => {
    navigate("/AdminPage");
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData({ ...formData, images: files });
  };

  const handlePublish = () => {
    if (
      !formData.title ||
      !formData.description ||
      formData.images.length === 0
    ) {
      setAllFieldsError(true);
      return;
    }
  
    const uploadTasks = formData.images.map((image) => {
      const storageRef = ref(
        storage,
        `/OurWork/${Date.now()}${image.name}`
      );
  
      const uploadImage = uploadBytesResumable(storageRef, image);
  
      return new Promise((resolve, reject) => {
        uploadImage.on(
          "state_changed",
          (snapshot) => {
            const progressPercent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setProgress(progressPercent);
          },
          (err) => {
            console.log(err);
            reject(err);
          },
          () => {
            getDownloadURL(uploadImage.snapshot.ref)
              .then((url) => {
                resolve(url);
              })
              .catch((err) => {
                console.log(err);
                reject(err);
              });
          }
        );
      });
    });
  
    Promise.all(uploadTasks)
      .then((urls) => {
        const articleRef = collection(db, "OurWork");
  
        const post = {
          title: formData.title,
          description: formData.description,
          imageUrls: urls,
          createdAt: Timestamp.now().toDate(),
        };
  
        addDoc(articleRef, post)
          .then(() => {
            setError(false);
            setAllFieldsError(false);
            setSubmitted(true);
            setProgress(0);
            setFormData({
              title: "",
              description: "",
              images: [],
            });
          })
          .catch((err) => {
            setError(true);
          });
      })
      .catch((err) => {
        setError(true);
      });
  };

  return (
    <div style={{ marginTop: '10em', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2>Přidat položku</h2>
  
      <div style={{ marginBottom: '1em' }}>
        <label htmlFor="title" style={{ fontWeight: 'bold' }}>Název</label>
        <input
          type="text"
          name="title"
          id="title"
          style={{ width: '100%' }}
          value={formData.title}
          onChange={(e) => handleChange(e)}
        />
      </div>
  
      <div style={{ marginBottom: '1em' }}>
        <label htmlFor="description" style={{ fontWeight: 'bold' }}>Popis</label>
        <textarea
          name="description"
          id="description"
          style={{ width: '100%' }}
          value={formData.description}
          onChange={(e) => handleChange(e)}
        />
      </div>
  
      <div style={{ marginBottom: '1em' }}>
        <label htmlFor="image" style={{ fontWeight: 'bold' }}>Fotky</label>
        <input
          type="file"
          name="image"
          id="image"
          accept="image/*"
          style={{ width: '100%' }}
          onChange={(e) => handleImageChange(e)}
          multiple
        />
        {progress === 0 ? null : (
          <div>
            <div style={{ width: `${progress}%` }}>{`uploading images ${progress}%`}</div>
          </div>
        )}
      </div>
  
      <div style={{ marginBottom: '2em' }}>
        <button onClick={handlePublish}>Přidat</button>
        <button onClick={handleReturnBack} style={{ marginLeft: '1em' }}>
          Zpět
        </button>
      </div>
  
      <div style={{ maxWidth: '400px', margin: '10px auto' }}>
        {submitted && !error && (
          <div style={{ marginBottom: '1em' }}>
            <Alert severity="success" onClose={() => setSubmitted(false)}>
              Položka přidána.
            </Alert>
          </div>
        )}
  
        {error && (
          <div style={{ marginBottom: '1em' }}>
            <Alert severity="error" onClose={() => setError(false)}>
              Něco se pokazilo. Zkuste prosím znovu.
            </Alert>
          </div>
        )}
  
        {allFieldsError && (
          <div style={{ marginBottom: '1em' }}>
            <Alert severity="error" onClose={() => setAllFieldsError(false)}>
              Vyplňte všechny pole.
            </Alert>
          </div>
        )}
      </div>
      <div>
        <PastProjectsListAdmin />
      </div>
    </div>
  );
}