import Contact from './pages/Contact';
import OurWork from './pages/OurWork';
import Home from './pages/Home';
import Transport from './pages/Transport';
import Construction from './pages/Construction';
import MaterialResale from './pages/MaterialResale';

export const navBarRoutes = [
    { path: "/", element: <Home /> },
    { path: "STAVEBNÍ PRÁCE", element: <Construction /> },
    { path: "NAŠE PRÁCE", element: <OurWork /> },
    { path: "KONTAKTY", element: <Contact /> },
    { path: "AUTODOPRAVA", element: <Transport /> },
    { path: "PŘEPRODEJ MATERIÁLU", element: <MaterialResale /> },
  ];
  
export const footerRoutes = [
  { path: "NAŠE PRÁCE", element: <OurWork /> },
  { path: "KONTAKTY", element: <Contact /> },
];