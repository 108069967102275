import ContactForm from "../components/ContactForm";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import '../App.css';

const Contact = () => {
  return(
    <Box>
     <Box className="Contact">
        <Box sx={{ display: 'flex', alignItems: 'center',mx:{sm:24,xs:5}, pt:{sm:24,xs:20}}}>
          <Typography sx={{ mx: 2,typography: { sm: 'h4', xs: 'h6' } }}>KONTAKTNÍ OSOBA</Typography>
          <Box sx={{ flexGrow: 1 }}>
            <Divider sx={{ borderColor: '#FF750E' }} />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: {sm:'row',xs:'column'}, ml:{sm:24,xs:5}}}>
          <Box sx={{ display:'flex', flexDirection: 'column'}}>
            <Box className="ContactPerson">
              <Box sx={{ display: 'flex', flexDirection:'column'}}>
                <Typography sx={{display: 'flex', ml:5, alignItems: 'left',typography: { sm: 'h5', xs: 'h6' }}}><strong>Leoš Pačiska</strong></Typography>
                <Typography sx={{ display: 'flex', mt: 1, ml:7 ,typography: { sm: 'subtitle1', xs: 'subtitle2' }}}>
                  <PhoneIcon style={{ color: '#FF750E', marginRight: 4 }} /> +420 602 541 936
                </Typography>
                <Typography sx={{ display: 'flex', ml:7 ,typography: { sm: 'subtitle1', xs: 'subtitle2' }}}>
                  <EmailIcon style={{ color: '#FF750E', marginRight: 10 }} />jl.stavbyadoprava@gmail.com
                </Typography>
              </Box>
            </Box>
            <Box className="ContactPerson">
              <Box sx={{ display: 'flex', flexDirection:'column'}}>
                <Typography sx={{display: 'flex', ml:5, alignItems: 'left',typography: { sm: 'h5', xs: 'h6' }}}><strong>Jaromír Pačiska</strong></Typography>
                <Typography sx={{ display: 'flex', mt: 1, ml:7 ,typography: { sm: 'subtitle1', xs: 'subtitle2' }}}>
                  <PhoneIcon style={{ color: '#FF750E', marginRight: 4 }} /> +420 777 768 124
                </Typography>
                <Typography sx={{ display: 'flex', ml:7, typography: { sm: 'subtitle1', xs: 'subtitle2' }}}>
                  <EmailIcon style={{ color: '#FF750E', marginRight: 10 }} />jl.stavbyadoprava@gmail.com
                </Typography>
              </Box>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', ml:5, pt:{sm:6,xs:2}, backgroundColor:'#f9f9f9'}}>
              <Typography sx={{display: 'flex', textAlign: 'left',typography: { sm: 'h5', xs: 'h6' }}}><strong>Fakturační adresa</strong></Typography>
              <Typography sx={{display: 'flex', alignItems: 'left',typography: { sm: 'h5', xs: 'h6' }}}>J&L stavby a doprava s.r.o.</Typography>
              <Typography sx={{display: 'flex', alignItems: 'left', ml:2,typography: { sm: 'body1', xs: 'subtitle1' }}}>Lidická 700/19,602 00 Brno</Typography>
              <Typography sx={{display: 'flex', alignItems: 'left', ml:2,typography: { sm: 'body1', xs: 'subtitle1' }}}>IČ: 14343436, DIČ: CZ14343436</Typography>
            </Box>
          </Box>
          <Box sx={{display: 'flex', pl: {sm:44,xs:5}}}>
            <ContactForm />
          </Box>
        </Box>
      </Box>
    </Box>
  )
};

export default Contact;