import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import React, { useState } from 'react'
import Axios from 'axios'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import '../App.css';

const sendEmailURL = 'https://us-central1-jl-stavby.cloudfunctions.net/sendEmail';
    
const ContactForm = () => {
  const [state, setState] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    text: '',
    service: 'Hrubé stavby',
  });
  
  const { name, company, email, phone, text, service } = state;

  const handleState = ({ target: { id, value } }) => {
    setState({ ...state, [id]: value });
    setErrors({ ...errors, [id]: false }); // Clear the error state when input changes
  };

  const handleSelect = (event) => {
    const { value } = event.target;
    setState({ ...state, service: value });
  };

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    text: false
  });

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{9}$/;
    return phoneRegex.test(phone);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Perform field validation
    let hasErrors = false;

    if (!name) {
      setErrors((prevState) => ({ ...prevState, name: true }));
      hasErrors = true;
    }

    if (!email) {
      setErrors((prevState) => ({ ...prevState, email: true }));
      hasErrors = true;
    } else if (!validateEmail(email)) {
      setErrors((prevState) => ({ ...prevState, emailFormat: true }));
      hasErrors = true;
    }

    if (phone && !validatePhoneNumber(phone)) {
      setErrors((prevState) => ({ ...prevState, phone: true }));
      hasErrors = true;
    }

    if (!text) {
      setErrors((prevState) => ({ ...prevState, text: true }));
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    let message = `Jméno: ${name}`;
    if (company) {
        message += `, ${company}`;
      }
      message += `\nE-mail: ${email}\nTel: ${phone}\n${service}:\n${text}`;
    const subject = `${name} - ${service}`;

    Axios.get(sendEmailURL, {
      params: {
        message,
        subject,
      },
    })
      .then(() => {
        setSubmitted(true);
        setState({
          name: '',
          company: '',
          email: '',
          phone: '',
          text: '',
          service: 'Hrubé stavby',
        });
        setErrors({
          name: false,
          email: false,
          phone: false,
          text: false,
        });
        setError(false);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      });
  };

    const services = [
      {
          value: 'Hrubé stavby',
          label: 'Hrubé stavby',
        },
        {
          value: 'PVC a Asfaltové izolace',
          label: 'PVC a Asfaltové izolace',
        },
        {
          value: 'Střechy',
          label: 'Střechy',
        },
        {
          value: 'Fasády a tepelné izolace',
          label: 'Fasády a tepelné izolace',
        },
        {
          value: 'Přípojky',
          label: 'Přípojky',
        },
        {
          value: 'Výkopové práce',
          label: 'Výkopové práce',
        },
      ];

      return (
        <form className="contact-form" noValidate autoComplete="off" onSubmit={sendEmail}>
          <div className="row">
            <TextField
              required
              id="name"
              label="Jméno a příjmení"
              margin="normal"
              className="form-field-wide"
              value={name}
              onChange={handleState}
              error={errors.name}
              helperText={errors.name ? 'Vyplňte toto pole' : ''}
            />
          </div>
          <div className="row">
            <TextField
              id="company"
              label="Firma"
              margin="normal"
              className="form-field-wide"
              value={company}
              onChange={handleState}
            />
          </div>
          <div className="row">
            <TextField
              required
              type="email"
              id="email"
              label="E-mail"
              margin="normal"
              className="form-field"
              value={email}
              onChange={handleState}
              error={errors.email || (email && !validateEmail(email))}
              helperText={
                errors.email
                  ? 'Vyplňte toto pole'
                  : email && !validateEmail(email)
                  ? 'Neplatný formát'
                  : ''
              }
              sx={{ marginRight: '0.5rem' }}
            />
            <TextField
              id="phone"
              label="Telefon"
              margin="normal"
              className="form-field"
              value={phone}
              onChange={handleState}
              error={errors.phone}
              helperText={errors.phone ? 'Neplatný formát' : ''}
              sx={{ marginLeft: '0.5rem' }}
            />
          </div>
          <div className="row">
            <TextField
              required
              id="text"
              label="Zpráva"
              margin="normal"
              multiline
              rows={5}
              className="form-field-wide"
              value={text}
              onChange={handleState}
              error={errors.text}
              helperText={errors.text ? 'Vyplňte toto pole' : ''}
            />
          </div>
          <div>
            <Typography sx={{ display: 'flex', justifyContent: 'left', pt: 2 }}>
              Služba, o kterou mám zájem:
            </Typography>
            <TextField
              className="form-field-wide"
              id="service"
              select
              value={service}
              onChange={handleSelect}
            >
              {services.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <Button
            type="submit"
            variant="contained"
            sx={{
              mr: 2,
              color: 'white',
              '&:hover': {backgroundColor: '#313131',},
              backgroundColor: '#FF750E',
              borderRadius: 0,
              pl: '0.5rem',
              mt: 2,
            }}
            startIcon={<SendIcon sx={{ paddingLeft: 1, color: 'white' }} />}
          >
            ODESLAT
          </Button>
          {submitted && !error && (
            <Alert severity="success" onClose={() => setSubmitted(false)} sx={{ mt: 2 }}>
              <AlertTitle>Děkujeme za zprávu</AlertTitle>
              Co nejdříve se vám ozveme.
          </Alert>
          )}

          {error && (
            <Alert severity="error" onClose={() => setError(false)} sx={{ mt: 2 }}>
              <AlertTitle>Něco se pokazilo</AlertTitle>
              Zkuste prosím znovu.
            </Alert>
          )}
        </form>
      );
    };
    
    export default ContactForm;