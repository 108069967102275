import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAS3ByNTXVcBApZsCivNMqXDkvwFxf4ZEc",
  authDomain: "jl-stavby.firebaseapp.com",
  projectId: "jl-stavby",
  storageBucket: "jl-stavby.appspot.com",
  messagingSenderId: "1066070407693",
  appId: "1:1066070407693:web:155fb63162be8e5c9fb344"
};

const app = initializeApp(firebaseConfig);  
export const storage = getStorage(app)
export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();