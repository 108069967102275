import PastProjectsList from "../components/PastProjectsList";
import { Typography, Box } from "@mui/material";
import bacgroundImg from '../assets/construction-site.jpg';
import dotsBackground from '../assets/dots_background.png';
import Divider from '@mui/material/Divider';

const OurWork = () => {
    return (
      <Box>
        <Box sx={{
            height: { xs: '400px', sm: '500px'},
            backgroundImage: `url(${bacgroundImg})`,
            backgroundSize: 'cover',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            color: 'white'
        }}>
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            mt: {sm:30,xs:15}
            }}>
            <Typography sx={{ color: '#FF750E' ,typography: { sm: 'h2', xs: 'h4' }}}>MINULÉ PROJEKTY</Typography>
          </Box>
        </Box>
    
        <Box sx={{ 
          backgroundImage: `url(${dotsBackground})`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          display: 'flex',
          flexDirection: 'column',
          pb:10
          }}>
          <Box sx={{ display:'flex', flexDirection: 'column', textAlign: 'center', pt:{sm:10,xs:5}, px:{sm:0,xs:2}}}>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}}>Věnujeme se realizaci rozmanitých projektů s důrazem na kvalitu a preciznost. </Typography>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}} gutterBottom="true">V této sekci vám přinášíme vizuální ukázky našich dokončených stavebních zakázek,</Typography>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}}>Věříme, že každý stavební projekt představuje unikátní výzvu,</Typography>
              <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}} gutterBottom="true">a proto jsme připraveni vám ukázat naši širokou škálu dosažených výsledků.</Typography>
          </Box>
        </Box>
        <Divider sx={{ backgroundColor: '#FF750E',height: '2px', my:{sm:5,xs:1} ,mx:{sm:20,xs:5}}} />
        <Box sx={{mx:{sm:28,xs:5}, pb:{sm:10,xs:5}}}>
          <PastProjectsList />
        </Box>
      </Box>
    )
  };
  
  export default OurWork;