import React, { useState, useEffect, useRef } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from '@mui/icons-material/Close';
import "../App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { storage } from "../firebase";
import { Box, Modal, IconButton } from "@mui/material";

const SamplePrevArrow = (props) => {
  const { className, onClick } = props;

  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIosIcon fontSize="large" style={{ color: "#FF750E" }} />
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIosIcon fontSize="large" style={{ color: "#FF750E" }} />
    </div>
  );
};

const Responsive = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef(null);

  useEffect(() => {
    fetchImageUrls();
  }, []);

  const fetchImageUrls = async () => {
    try {
      const folderRef = ref(storage, "/HomeCarousel");
      const fileList = await listAll(folderRef);

      const urls = await Promise.all(
        fileList.items.map(async (item) => {
          const downloadUrl = await getDownloadURL(item);
          return downloadUrl;
        })
      );

      setImageUrls(urls);
    } catch (error) {
      console.log(error);
    }
  };

  const openModal = (url) => {
    if (!isDragging) {
      setSelectedImage(url);
    }
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleImageClick = (url) => {
    openModal(url);
  };

  return (
    <div>
      <Slider
        dots={false}
        infinite={true}
        speed={500}
        slidesToShow={3}
        slidesToScroll={1}
        nextArrow={<SampleNextArrow />}
        prevArrow={<SamplePrevArrow />}
        centerMode={true}
        centerPadding="0"
        ref={sliderRef}
        onSwipe={handleDragStart}
        onTouchMove={handleDragStart}
        onTouchEnd={handleDragEnd}
        onMouseDown={handleDragStart}
        onMouseMove={handleDragStart}
        onMouseUp={handleDragEnd}
        responsive={[
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              centerMode: false,
              centerPadding: '0',
            },
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              centerMode: false,
              centerPadding: '0',
            },
          },
        ]}
      >
        {imageUrls.map((url, index) => (
          <Box key={index}  >
            <img
              src={url}
              alt={`Image ${index}`}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                cursor: 'pointer',
              }}
              onMouseDown={handleDragStart}
              onMouseMove={handleDragStart}
              onMouseUp={handleDragEnd}
              onClick={() => handleImageClick(url)}
            />
          </Box>
        ))}
      </Slider>

      <Modal open={Boolean(selectedImage)} onClose={closeModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '15%',
            left: '15%',
            right: '15%',
            bottom: '15%',
            backgroundColor: '#FFFFFF',
            padding: '3rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={selectedImage}
            alt="Selected Project"
            style={{ width: "auto", height: "100%", maxHeight: "100%",border: "3px solid #FF750E" }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default Responsive;
