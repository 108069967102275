import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import RoomIcon from '@mui/icons-material/Room';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import logo_white from '../assets/logo_white.png';
import '../App.css';

function Footer() {
  return (
    <Box sx={{ bgcolor: '#f9f9f9'}}>
      <Box sx={{ 
        bgcolor: '#313131', p: 6,
        clipPath: {
          sm:'polygon(25% 0, 30% 25%, 100% 25%, 100% 100%, 0 100%, 0 0)',
          xs:'polygon(25% 15%, 30% 25%, 100% 25%, 100% 100%, 0 100%, 0 15%)' 
        }
        }}>
        <Grid container spacing={0}   
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            >
          <Grid item xs={12} md={4} sx={{display:{sm:'block',xs:'none'}}}>
            <img src={logo_white} alt="Logo" width="266,8mm" height="auto" />
            <Typography sx={{ paddingTop: 2 , marginLeft: 10}}>Autodoprava a stavební práce pro Brno a okolí.</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box Box sx={{ display: 'flex', alignItems: 'center', ml: {sm:5,xs:0}, mt: {sm:5,xs:10} }}>
              <Box style={{ textAlign: 'left' }}>
                <Typography variant="h6">
                  Kontakty
                </Typography>
                  <div style={{ height: 0, borderBottom: '2px solid #FF750E', margin: '0 auto', width: 65, marginLeft: 0 }} />
                <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                  <PhoneIcon style={{ color: '#FF750E', marginRight: 4 }} /> +420 602 541 936
                </Typography>
                <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
                  <EmailIcon style={{ color: '#FF750E', marginRight: 10 }} />
                  <a href="mailto:jl.stavbyadoprava@gmail.com" style={{ color: 'white', textDecoration: 'none' }}>jl.stavbyadoprava@gmail.com</a>
                </Typography>
                <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
                  <RoomIcon style={{ color: '#FF750E', marginRight: 10 }} />
                  <a href="https://www.google.com/maps/search/Lidick%C3%A1+700%2F19,602+00+Brno/@49.2018019,16.6045486,17z/data=!3m1!4b1?authuser=0&entry=ttu" 
                  target="_blank" 
                  rel="noopener noreferrer"
                   style={{ color: 'inherit', textDecoration: 'none' }}>
                    Lidická 700/19,602 00 Brno
                   </a>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
          <Box style={{ textAlign: 'left', marginTop: 40 }}>
            <Typography variant="h6">Odkazy</Typography>
            <div style={{ height: 0, borderBottom: '2px solid #FF750E', margin: '0 auto', width: 65, marginLeft: 0 }} />
            <Typography variant="subtitle1" gutterBottom style={{ display: 'flex', flexDirection: 'column', marginTop: 5 }}>
              <Link to="/NAŠE PRÁCE" className="footer-link">Naše práce</Link>
              <Link to="/KONTAKTY" className="footer-link">Kontakty</Link>
              <Link to="/GDPR" className="footer-link">Ochrana osobních údajů GDPR</Link>
            </Typography>
          </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Footer;