import React, { useState, useEffect } from "react";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { storage, db } from "../firebase";
import { deleteObject, ref } from "firebase/storage";
import { Typography, Button, Paper, Grid, Box } from "@mui/material";
import Divider from '@mui/material/Divider';

export default function PastProjectsList() {
  const [posts, setPosts] = useState([]);

  const handleDelete = async (id, imageUrls) => {
    if (window.confirm("Opravdu smazat?")) {
      try {
        await deleteDoc(doc(db, "OurWork", id));

        // Delete images from storage
        imageUrls.forEach((imageUrl) => {
          const storageRef = ref(storage, imageUrl);
          deleteObject(storageRef);
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const postRef = collection(db, "OurWork");
    const q = query(postRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const posts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(posts);
    });
  }, []);

  return (
    <div>
      {posts.length === 0 ? (
        <p>Žádné položky</p>
      ) : (
        posts.map(({ id, title, description, imageUrls }) => {
          return (
            <Paper key={id} elevation={3} sx={{ mt: 3, p: 6, mx: {sm:28,xs:2}}}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <Grid container direction="column" alignItems="flex-start">
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Typography sx={{typography:{sm:'h4',xs:'h6'}}}>{title}</Typography>
                      </Grid>
                      <Grid item>
                        <Button onClick={() => handleDelete(id, imageUrls)}>
                          Smazat
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item sx={{ textAlign: "left" }}>
                      <Typography sx={{typography:{sm:'text',xs:'subtitle2'}}}>{description}</Typography>
                      <Divider sx={{ backgroundColor: '#FF750E',height: '2px', my: 3}} />
                    </Grid>
                    <Grid item>
                    <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gap="1em">
                      {imageUrls.map((imageUrl) => (
                        <img
                          key={imageUrl}
                          src={imageUrl}
                          alt="Project"
                          style={{ width: "100%", height: "auto" }}
                        />
                      ))}
                    </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          );
        })
      )}
    </div>
  );
}
