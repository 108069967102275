import React from "react";
import { UserAuth } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';

const AdminPage = () => {
    const {user, logout} = UserAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
          await logout();
          navigate('/');
          console.log('You are logged out')
        } catch (e) {
          console.log(e.message);
        }
      };

    const handleCreatePost = () => {
        navigate("/CreatePost"); 
      };

    const handleAddPastProjects = () => {
      navigate("/AddPastProjects");
    };

    const handleAddCarouselImage = () => {
      navigate("/AddCarouselImage");
    };

    return (
        <Box sx={{mt:'10em',display:'flex',flexDirection:{sm:'row',xs:'column'},alignItems:'center',justifyContent:'center'}}>
            <Button variant="contained" onClick={handleCreatePost} sx={{'&:hover': {backgroundColor: '#313131',},backgroundColor: '#FF750E', borderRadius: 0}}>
              Položky bazaru
            </Button>
            <Button variant="contained" onClick={handleAddPastProjects} sx={{'&:hover': {backgroundColor: '#313131',},backgroundColor: '#FF750E', borderRadius: 0}}>
              Dokončené projekty
            </Button>
            <Button variant="contained" onClick={handleAddCarouselImage} sx={{'&:hover': {backgroundColor: '#313131',},backgroundColor: '#FF750E', borderRadius: 0}}>
              Slideshow na hlavní stránce
            </Button>
            <Button variant="contained" onClick={handleLogout} sx={{'&:hover': {backgroundColor: '#313131',},backgroundColor: '#FF750E', borderRadius: 0}}>
                Odhlásit
            </Button>
        </Box>
    )
  };
  
  export default AdminPage;