import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import bannerImg from '../assets/HomeImg.jpg';
import worker from '../assets/worker.jpg';
import dotsBackground from '../assets/dots_background.png';
import construction from '../assets/construction.png';
import helmet from '../assets/helmet.png';
import lorry from '../assets/lorry.png';
import diy from '../assets/diy.png';
import costumer from '../assets/costumer.png';
import excavator from '../assets/excavator.png';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions } from '@mui/material';
import Grid from '@mui/material/Grid';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Divider from '@mui/material/Divider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ImageCarousel from '../components/ImageCarousel.js';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ContactForm from '../components/ContactForm';

const Home = () => {
    const [open, setOpen] = useState(false);
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <Box>
       <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 8,
            maxWidth: { sm: 500, xs: 250},
            width: '90%',
            maxHeight: '90%',
            overflowY: 'auto',
            border: '3px solid #FF750E',
            borderRadius: '8px',
          }}
        >
          <h2 id="modal-title">Kontaktujte nás</h2>
          <ContactForm />
        </Box>
      </Modal>

      <Box sx={{
        height: { xs: '400px', sm: '500px'},
        backgroundImage: `url(${bannerImg})`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'left',
        textAlign: 'left',
        color: 'white',

      }}>
        <Box sx={{ ml: { xs: 2, sm: 25 }, mt: { xs: 10, sm: 15 } }}>
          <Typography sx={{ color: '#FF750E', typography: { sm: 'h3', xs: 'h4' }}}>BRNO A OKOLÍ</Typography>
          <Typography sx={{ mb: 2, color: 'white' ,typography: { sm: 'h5', xs: 'body1' }}}>AUTODOPRAVA A STAVEBNÍ PRÁCE</Typography>
          <Typography variant="body1" sx={{ mb: 4, color: 'white' }}>Kvalitně odvedená práce za férové ceny.</Typography>
          <Box sx={{ display: 'flex' }}>
              <Button variant="contained" sx={{ mr: 2, color: "white",'&:hover': {backgroundColor: '#313131',}, backgroundColor: '#FF750E', borderRadius: 0 }} onClick={handleOpen}>POPTÁVKA</Button>
            <Link to="/NAŠE PRÁCE">
              <Button variant="outlined" sx={{'&:hover': {borderColor: '#313131',}, borderColor: '#FF750E', color: "white", borderRadius: 0 }}>NAŠE PRÁCE</Button>
            </Link>
          </Box>
        </Box>
      </Box>
     <Box sx={{ 
        backgroundImage: `url(${dotsBackground})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center',mx:{sm:24, xs:5}, pb:{sm:6,xs:0}, pt:{sm:12,xs:3}}}>
          <Typography  sx={{ mx: 2, typography: {sm: 'h4', xs: 'h6'}}}>NAŠE SLUŽBY</Typography>
          <Box sx={{ flexGrow: 1 }}>
            <Divider sx={{ borderColor: '#FF750E' }} />
          </Box>
        </Box>
        <Box sx={{ display:'flex', alignItems: 'center', flexDirection: { xs: 'column', md: 'row' },gap:{sm: 15, xs: 3}, py: 6 ,px:{sm:24,xs:16}}}>
            <Card sx={{ width: { xs: 250, md: 450 },height:  { xs: 300, md: 500 }, bgcolor: "#f9f9f9" }}>
              <CardMedia
                component="img"
                height="50%"
                image={lorry}
                alt="lorry"
                sx={{width:'70%', display: "flex", margin:'auto', objectFit: "contain",pt:1,pb:{ xs: 0, sm: 6 }}}
              />
              <CardContent sx={{ p: {xs: 0, sm: '16px'}}}>
                <Typography gutterBottom variant="h5" component="div">
                  AUTODOPRAVA
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  K dispozici více než 5 moderních vozidel.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Link to="/AUTODOPRAVA">
                  <Button size="small" color="primary">
                    <ArrowForwardIosIcon style={{ color: '#FF750E' }} />
                  </Button>
                </Link>
              </CardActions>
            </Card>
            <Card sx={{ width: { xs: 250, md: 450 },height:  { xs: 300, md: 500 }, bgcolor: "#f9f9f9" }}>
              <CardMedia
                component="img"
                height="50%"
                image={construction}
                alt="construction"
                sx={{width:'70%', display: "flex", margin:'auto', objectFit: "contain",pt:2,pb:{ xs: 0, sm: 6 }}}
              />
              <CardContent sx={{ p: {xs: 0, sm: '16px'}}}>
                <Typography gutterBottom variant="h5" component="div">
                  PŘEPRODEJ MATERIÁLU
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Přeprodáváme kvalitní stavební
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Link to="/PŘEPRODEJ MATERIÁLU">
                  <Button size="small" color="primary">
                    <ArrowForwardIosIcon style={{ color: '#FF750E' }} />
                  </Button>
                </Link>
              </CardActions>
            </Card>
            <Card sx={{width: { xs: 250, md: 450 },height:  { xs: 300, md: 500 }, bgcolor: "#f9f9f9" }}>
              <CardMedia
                component="img"
                height="50%"
                image={helmet}
                alt="helmet"
                sx={{width:'70%', display: "flex", margin:'auto', objectFit: "contain",pt:1,pb:{ xs: 0, sm: 6 }}}
              />
              <CardContent sx={{ p: {xs: 0, sm: '16px'}}}>
                <Typography gutterBottom variant="h5" component="div">
                  STAVEBNÍ PRÁCE
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Provádíme stavební práce všeho druhu.
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Link to="/STAVEBNÍ PRÁCE">
                  <Button size="small" color="primary">
                    <ArrowForwardIosIcon style={{ color: '#FF750E' }} />
                  </Button>
                </Link>
              </CardActions>
            </Card>
        </Box>
      </Box>
     
      <Box sx={{ bgcolor: "#f9f9f9"}}>
        <Box sx={{ display: 'flex', alignItems: 'center', mx:{sm:24, xs:5}, py:{sm:6,xs:3}}}>
          <Typography sx={{ mx: 2, color: 'black', typography: {sm: 'h4', xs: 'h6'} }}>KDO JSME</Typography>
          <Box sx={{ flexGrow: 1 }}>
            <Divider sx={{ borderColor: '#FF750E' }} />
          </Box>
        </Box>
        <Grid  container spacing={0}>
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={12} md={5}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={12}>
                <Typography>
                  <Typography sx={{typography:{sm:'subtitle1',xs:'subtitle2'}}}>Jsme česká firma, která se již několik let specializuje na komplexní </Typography>
                  <Typography  sx={{typography:{sm:'subtitle1',xs:'subtitle2'}}} gutterBottom="true">stavební práce, přeprodej materiálu a s tím spojenou dopravu.</Typography>
                </Typography>
                <Typography>
                  <Typography  sx={{typography:{sm:'subtitle1',xs:'subtitle2'}}}>Naším hlavním cílem je,aby práce byla vykonána kvalitně a opravdu důsledně.</Typography>
                  <Typography  sx={{typography:{sm:'subtitle1',xs:'subtitle2'}}} >Mezi další výhody patří flexibilita. Je možné si domluvit termín ve svátek,</Typography>
                  <Typography  sx={{typography:{sm:'subtitle1',xs:'subtitle2'}}}>víkend či noční. Nejčastější využití této flexibility je</Typography>
                  <Typography  sx={{typography:{sm:'subtitle1',xs:'subtitle2'}}}>pro opravy budov s nepřetržitým denním provozem například obchodní domy, polikliniky, výrobny.</Typography>
                </Typography>
              </Grid>
              <Grid item marginLeft={8} marginTop={5} xs={12} md={12}>
                <Grid textAlign={"left"} container spacing={0}>
                  <Grid item xs={0} md={2}></Grid>
                  <Grid item xs={6} md={4}><Typography  sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}   variant="subtitle1"><CheckCircleIcon style={{ color: '#FF750E' }}/>Česká firma</Typography></Grid>
                  <Grid item xs={6} md={4}><Typography sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}  variant="subtitle1"><CheckCircleIcon style={{ color: '#FF750E' }}/> Důvěryhodný podnik</Typography></Grid>
                  <Grid item xs={0} md={2}></Grid>
                  <Grid item xs={0} md={2}></Grid>
                  <Grid item xs={6} md={4}><Typography sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}  variant="subtitle1"><CheckCircleIcon style={{ color: '#FF750E' }}/> Mnoho zkušeností</Typography></Grid>
                  <Grid item xs={6} md={4}><Typography sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}  variant="subtitle1"><CheckCircleIcon style={{ color: '#FF750E' }}/> Flexibilita</Typography></Grid>
                  <Grid item xs={0} md={2}></Grid>
                  <Grid item xs={0} md={2}></Grid>
                  <Grid item xs={6} md={4}><Typography sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}  variant="subtitle1"><CheckCircleIcon style={{ color: '#FF750E' }}/> Tým plný odborníků</Typography></Grid>
                  <Grid item xs={6} md={4}><Typography sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}  variant="subtitle1"><CheckCircleIcon style={{ color: '#FF750E' }}/> Dodržování termínů</Typography></Grid>
                  <Grid item xs={0} md={2}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={0} md={5} sx={{ display: { xs: 'none', md: 'block' }}}>
            <img src={worker} height={"500px"} width={"auto"} alt="Worker" />
          </Grid>
          <Grid item xs={0} md={1}></Grid>
        </Grid>
      </Box>

      <Grid container spacing={1} sx={{ bgcolor: "#f9f9f9", py:{sm:15,xs:8}, px:24}}>
        <Grid item xs={12} md={4}>
          <Grid container spacing={0}>
            <Grid item xs={0} md={6}>
              <img src={costumer} alt="costumer" width={150} height={150} />
            </Grid>
            <Grid item xs={0} md={6}>
              <Grid container direction="column" justify="center" alignItems="center">
                <Grid item xs={0} md={12}>
                  <Typography fontSize={70}>150</Typography>
                </Grid>
                <Grid item xs={0} md={12}>
                  <Typography fontSize={20} textAlign="center">SPOKOJENÝCH KLIENTŮ</Typography>
                </Grid>
               </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
        <Grid container spacing={0}>
            <Grid item xs={0} md={6}>
              <img src={excavator} alt="excavator" width={150} height={150} />
            </Grid>
            <Grid item xs={0} md={6}>
              <Grid container direction="column" justify="center" alignItems="center">
                <Grid item xs={0} md={12}>
                  <Typography fontSize={70}>11</Typography>
                </Grid>
                <Grid item xs={0} md={12}>
                  <Typography fontSize={20} textAlign="center">MODERNÍCH STROJŮ</Typography>
                </Grid>
               </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
        <Grid container spacing={0}>
            <Grid item xs={0} md={6}>
              <img src={diy} alt="diy" width={150} height={150} />
            </Grid>
            <Grid item xs={0} md={6}>
              <Grid container direction="column" justify="center" alignItems="center">
                <Grid item xs={0} md={12}>
                  <Typography fontSize={70}>7</Typography>
                </Grid>
                <Grid item xs={0} md={12}>
                  <Typography fontSize={20} textAlign="center">LET V OBORU</Typography>
                </Grid>
               </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ 
        backgroundImage: `url(${dotsBackground})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: {sm:"500px",xs:"250px"},
        width: "cover",
        paddingBottom: {sm:6,xs:0}}}>
        <Box sx={{ display: 'flex', alignItems: 'center',mx:{sm:24, xs:5}, my:{sm:6,xs:3} }}>
          <Typography variant="h4" sx={{ mx: 2, color: 'black', typography: {sm: 'h4', xs: 'h6'} }}>NAŠE PRÁCE</Typography>
          <Box sx={{ flexGrow: 1 }}>
            <Divider sx={{ borderColor: '#FF750E' }} />
          </Box>
        </Box>
        <Grid container spacing={0}>
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={10} md={10}>
            <ImageCarousel />
          </Grid>
          <Grid item xs={1} md={1}></Grid>
          <Grid item xs={12} md={12} sx={{pt:{sm:3,xs:1}}}>
            <Link to="/NAŠE PRÁCE" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              sx={{
                mr: 2,
                color: 'white',
                '&:hover': {backgroundColor: '#313131',},
                backgroundColor: '#FF750E',
                borderRadius: 0, 
                paddingLeft: '0.5rem',
              }}
              startIcon={<EastOutlinedIcon sx={{paddingLeft:1, color: 'white' }} />}
            >
              VÍCE UKÁZEK
            </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: "#f9f9f9",px:{sm:24,xs:5}, py:{sm:6,xs:3} }}>
          <Typography variant="h4" sx={{ mx: 2, color: 'black' , typography:{sm:'h4',xs:'h6'}}}>POPTÁVKA</Typography>
          <Box sx={{ flexGrow: 1 }}>
            <Divider sx={{ borderColor: '#FF750E' }} />
          </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' ,bgcolor: "#f9f9f9", px:{sm:24,xs:6}, pb:6}}>
        <Grid container spacing={0}>
          <Grid item xs={0} md={3}>
            <Typography variant="subtitle1" sx={{ mx: 2, color: 'black' }}>Máte zájem o naše služby? Kontaktujte nás!</Typography>
          </Grid>
          <Grid item xs={0} md={3}>
            <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', paddingLeft: {sm:15,xs:2} }}>
              <PhoneIcon style={{ color: '#FF750E', marginRight: 4}} />
                +420 602 541 936
            </Typography>
          </Grid>
          <Grid item xs={0} md={3}>
            <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', paddingLeft: {sm:0,xs:2}}}>
              <EmailIcon style={{ color: '#FF750E', paddingRight: 6}} />
                jl.stavbyadoprava@gmail.com
            </Typography>
          </Grid>
          <Grid item xs={0} md={3} sx={{pl:{sm:0,xs:2},pt:{sm:0,xs:1}}}>
            <Button
              variant="contained"
              sx={{
                mr: 2,
                color: 'white',
                '&:hover': {backgroundColor: '#313131',},
                backgroundColor: '#FF750E',
                borderRadius: 0, 
                pl: '0.5rem',
              }}
              startIcon={<EastOutlinedIcon sx={{paddingLeft:2, color: 'white' }} />}
              onClick={handleOpen}
            >
              ZADAT POPTÁVKU
            </Button>
            </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Home;

