import React from 'react';
import { Box, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

const GDPRPage = () => {
  return (
    <Box>
      <Box sx={{ pb: {sm:4,xs:0} , pt:{sm:25,xs:18}, textAlign: 'left', px:{sm:28,xs:5}}}>
        <Typography sx={{ mb: {sm:2,xs:0},typography: { sm: 'h4', xs: 'h6' } }}>
          Zásady práce s vašimi údaji
        </Typography>
        <Divider sx={{ backgroundColor: '#FF750E',height: '2px', my:{sm:5,xs:1} }} />
        <Typography sx={{ mb: 2,typography: { sm: 'body1', xs: 'subtitle2' } }}>
          Jsme společnost J&L stavby a doprava.
        </Typography>
        <Typography sx={{ mb: 2,typography: { sm: 'body1', xs: 'subtitle2' } }}>
          Pro poskytování našich služeb a provoz našich webových stránek zpracováváme některé osobní údaje.
        </Typography>
        <Typography sx={{ mb: 2,typography: { sm: 'body1', xs: 'subtitle2' } }}>
          Zpracování osobních údajů upravuje zejména nařízení Evropského parlamentu a Rady (EU) 2016/679 ze dne 27. dubna 2016 o ochraně fyzických sobo v souvislosti se  zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) („GDPR“)
        </Typography>
        <Typography sx={{ mb: 2,typography: { sm: 'h5', xs: 'h6' } }}>
          I. Zpracování osobních údajů
        </Typography>
        <Typography sx={{ mb: 2,typography: { sm: 'body1', xs: 'subtitle2' } }}>
          Zpracování osobních údajů v případě použití kontaktního formuláře:
        </Typography>
        <Typography sx={{ mb: 2,typography: { sm: 'body1', xs: 'subtitle2' } }}>
          Pokud poptáváte naše produkty a služby, budeme pracovat s vašimi kontaktními údaji, které nám sdělíte, hlavně prostřednictvím poptávkového formuláře. Jsou to: Jméno a příjmení, e-mailová adresa, telefonní číslo. 
        </Typography>
        <Typography sx={{ mb: 2,typography: { sm: 'body1', xs: 'subtitle2' } }}>
	        Z jakého důvodu? -
		      Kontaktujeme vás přes ně pro další domluvu ohledně zboží a služeb.
        </Typography>
        <Typography sx={{ mb: 2,typography: { sm: 'body1', xs: 'subtitle2' } }}>
		      Na základě jakého právního důvodu? -
          Jedná se o zpracování na základě čl. 6 odst. 1 písm. b) GDPR – jednání o smlouvě, resp. provedení opatření před uzavřením smlouvy na vaši žádost.
        </Typography>
        <Typography sx={{ mb: 2,typography: { sm: 'h5', xs: 'h6' } }}>
          II. Co byste dál měli vědět
        </Typography>
        <Typography sx={{ mb: 2,typography: { sm: 'body1', xs: 'subtitle2' } }}>
          V naší společnosti nedochází k rozhodování na základě automatického zpracování či profilování.
        </Typography>
        <Typography sx={{ mb: 2,typography: { sm: 'body1', xs: 'subtitle2' } }}>
          Pokud byste měli k osobním údajům otázky, kontaktujte nás na e-mailové adrese jl.stavbyadoprava@gmail.com   nebo zavolejte na tel. č. +420 602 541 936.
        </Typography>
        <Typography sx={{ mb: 2,typography: { sm: 'h5', xs: 'h6' } }}>
          III. Vaše práva v souvislosti se zpracováním osobních údajů
        </Typography>
        <Typography sx={{ mb: 2,typography: { sm: 'body1', xs: 'subtitle2' } }}>
          Nařízení GDPR vám dává mimo jiné právo obrátit se na nás a chtít informace, jaké vaše osobní údaje zpracováváme, vyžádat si u nás přístup k těmto údajům a nechat je  aktualizovat nebo opravit, popřípadě požadovat omezení zpracování, můžete požadovat kopii zpracovávaných osobních údajů, požadovat po nás v určitých situacích výmaz   osobních údajů a v určitých případech máte právo na jejich přenositelnost. Proti zpracování na základě oprávněného zájmu lze vznést námitku.
        </Typography>

        <Typography sx={{ mb: 2,typography: { sm: 'body1', xs: 'subtitle2' } }}>
          Pokud si myslíte, že s daty nenakládáme správně, máte právo podat stížnost u Úřadu pro ochranu osobních údajů, případně se se svými nároky obrátit na soud.
        </Typography>

        <Typography sx={{ mb: 2,typography: { sm: 'body1', xs: 'subtitle2' } }}>
          Tyto podmínky jsou účinné od 1.6.2023.
        </Typography>
      </Box>
    </Box>
  )
};

export default GDPRPage;