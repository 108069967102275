import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { signIn } = UserAuth();
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setError('')
      try {
        await signIn(email, password)
        navigate('/AdminPage')
      } catch (e) {
        setError(e.message)
        console.log(e.message)
      }
    };

    return (
        <div style={{marginTop:'10em'}}>
          <div>
            <h1>Přihlásit se</h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Email: </label>
              <input onChange={(e) => setEmail(e.target.value)} type='email' />
            </div>
            <div>
              <label>Heslo: </label>
              <input onChange={(e) => setPassword(e.target.value)} type='password' />
            </div>
            <button>
              Přihlásit se
            </button>
            {error && (
                <div style={{ maxWidth: '400px', margin: '10px auto' }}>
                  <Alert severity="error" onClose={() => setError(false)}>
                    Špatné údaje. Zkuste prosím znovu.
                  </Alert>
                </div>
            )}
          </form>

        </div>
      );
    };

export default Login;