import { collection, onSnapshot, orderBy, query, deleteDoc, doc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { storage, db } from "../firebase";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function BazarItemList() {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const postRef = collection(db, "Bazar");
    const q = query(postRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const posts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPosts(posts);
      console.log(posts);
    });
  }, []);

  return (
<Grid container spacing={2}>
      {posts.length === 0 ? (
        <Grid item xs={12}>
          <p>Žádné položky</p>
        </Grid>
      ) : (
        posts.map(({ id, title, price, description, imageUrl, createdAt }) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={id} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea onClick={() => navigate(`/BazarItemDetail/${id}`)}>
                <CardMedia
                  component="img"
                  height="140"
                  image={imageUrl}
                  alt={title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {title}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    {price}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      display: '-webkit-box',
                      '-webkit-line-clamp': 3,
                      '-webkit-box-orient': 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxHeight: '3em',
                    }}
                  >
                    {description}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button size="small" sx={{ color: '#FF750E' }}  onClick={() => navigate(`/BazarItemDetail/${id}`)}>
                  Zadat poptávku
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))
      )}
    </Grid>
  );
}