import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react'
import bacgroundImg from '../assets/bazar_bg.jpg';
import dotsBackground from '../assets/dots_background.png';
import Divider from '@mui/material/Divider';
import BazarItemList from '../components/BazarItemList';

const MaterialResale = () => {
  return (
    <Box>
      <Box sx={{
          height: { xs: '400px', sm: '500px'},
          backgroundImage: `url(${bacgroundImg})`,
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          color: 'white'
      }}>
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          mt: {sm:30,xs:15}
          }}>
          <Typography sx={{ color: '#FF750E' ,typography: { sm: 'h2', xs: 'h4' }}}>SLUŽBY</Typography>
          <Typography sx={{ color: 'white' ,typography: { sm: 'h3', xs: 'h5' }}}>PŘEPRODEJ MATERIÁLU</Typography>
        </Box>
      </Box>
  
      <Box sx={{ 
        backgroundImage: `url(${dotsBackground})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        pb:{sm:10,xs:0}
        }}>

        <Box sx={{ display:'flex', flexDirection: 'column', textAlign: 'center', pt:{sm:10,xs:5}, px:{sm:0,xs:2}}}>
            <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}}>S dlouholetými zkušenostmi v oboru vám můžeme zajistit materiály nejvyšší kvality, </Typography>
            <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}} gutterBottom="true">které splňují veškeré stavební normy a předpisy.</Typography>
            <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}}>Ať už potřebujete betonové směsi, cihly, izolace, dlažby</Typography>
            <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}} gutterBottom="true">jako jsou cihly, betonové bloky, kameny,nebo stavební konstrukce.</Typography>
            <Typography sx={{typography:{sm:'h6',xs:'subtitle1'}}}> nebo jiné stavební materiály, u nás najdete vše na jednom místě.</Typography>
        </Box>
      </Box>
      <Divider sx={{ backgroundColor: '#FF750E',height: '2px', my:{sm:5,xs:2} ,mx:{sm:20,xs:5}}} />
      <Box sx={{mx:{sm:28,xs:0}, pb:{sm:10,xs:1}}}>
        <BazarItemList />
      </Box>
    </Box>
  )
};
  
  export default MaterialResale;