import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { auth, db } from "../firebase";
import BazarForm from "./BazarForm";
import { Typography, Button, Paper, Grid, Box } from "@mui/material";
import Divider from '@mui/material/Divider';

export default function Article() {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const docRef = doc(db, "Bazar", id);
    onSnapshot(docRef, (snapshot) => {
      setPost({ ...snapshot.data(), id: snapshot.id });
    });
  }, []);

  return (
    <Box sx={{ mt: {sm:20,xs:15} }}>
      {post && (
        <Box sx={{ display: 'flex', flexDirection: {sm:'row',xs:'column'}, mx: {sm:28,xs:5} }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', pt: 5 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <img
                src={post.imageUrl}
                alt={post.title}
                className="image-container"
              />
              <Box sx={{ display: 'flex', flexDirection: 'column', pl: 2 }}>
                <Typography sx={{ textAlign: 'left', typography:{sm:'h4',xs:'h6'} }}>
                  {post.title}
                </Typography>
                <Typography sx={{pt:{sm:0,xs:2}, textAlign: 'left',typography:{sm:'h5',xs:'h6'},color: '#FF750E' }}>
                  {post.price}
                </Typography>
              </Box>
            </Box>
            <Typography sx={{ textAlign: 'left', mt: 2, typography:{sm:'body1',xs:'subtitle2'} }}>
              {post.description}
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ color: '#FF750E', typography:{sm:'h5',xs:'h6'} }}> Mám zájem</Typography>
            <Divider sx={{ backgroundColor: '#FF750E',height: '2px', my: 1}} />
            <BazarForm title={post.title}/>
          </Box>
        </Box>
      )}
    </Box>
  );
}