import Modal from '@mui/material/Modal';
import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import '../App.css';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import logo_black from '../assets/logo_black.png';
import ContactForm from '../components/ContactForm';

const pages = ['NAŠE PRÁCE', 'KONTAKTY'];

function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true)
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false)
  };
  const handleButtonHover = (event) => {
    setOpen(true);
  };

  const handleButtonLeave = (event) => {
    setOpen(false);
  };

  const [openModal, setOpenModal] = useState(false);
  
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 8,
            maxWidth: 500,
            width: '90%',
            maxHeight: '90%',
            overflowY: 'auto',
            border: '3px solid #FF750E',
            borderRadius: '8px',
          }}
        >
          <h2 id="modal-title">Kontaktujte nás</h2>
          <ContactForm />
        </Box>
      </Modal>

        <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' }, justifyContent: 'left', marginTop: 5, position: 'absolute' , zIndex:2, paddingLeft:12}}>
            <Link to="/">
                <img src={logo_black} alt="Logo" width="230px" height="86px" />
            </Link>
        </Box>
        <Box sx={{
          clipPath: 'polygon(0 0, 100% 0, 100% 100%, 30% 100%, 25% 75%, 0 75%)',
          bgcolor: '#eeeeee',
          position: 'absolute',
          width: '100%',
          paddingBottom: 3
        }}>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' }, justifyContent: 'center' }}>
                <Typography sx={{ display: 'flex', alignItems: 'center', paddingRight: 1, paddingTop: 1, typography: { xs: 'subtitle2', sm: 'subtitle1'} }}>
                    <PhoneIcon style={{ color: '#FF750E', marginRight: 5 }} /> +420 602 541 936
                </Typography>
                <Typography sx={{ display: 'flex', alignItems: 'center' , paddingLeft: 1, paddingTop: 1, typography: { xs: 'subtitle2', sm: 'subtitle1'} }}>
                    <EmailIcon style={{ color: '#FF750E', marginRight: 5 }} /> jl.stavbyadoprava@gmail.com
                </Typography>
            </Box>
        </Box>
        <Box sx={{
          clipPath: {
            xs: 'polygon(25% 15%, 30% 25%, 100% 25%, 100% 75%, 30% 75%, 25% 85%, 0 85%, 0 15%)',
            sm: 'polygon(25% 0, 30% 25%, 100% 25%, 100% 75%, 30% 75%, 25% 100%, 0 100%, 0 0)',
          },
          bgcolor: 'white',
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          marginTop: 3,
          paddingTop: 4,
          paddingBottom: 4
        }}>

            <Container maxWidth="xl">

            <Toolbar disableGutters >
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } , justifyContent: 'left', marginBottom: 1}}>
                    <Link to="/">
                        <img src={logo_black} alt="Logo" width="115px" height="43px" />
                    </Link>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } , justifyContent: 'right'}}>
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenNavMenu}
                      color="inherit"
                    >
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={Boolean(anchorElNav)}
                      onClose={handleCloseNavMenu}
                      sx={{
                        display: { xs: 'block', md: 'none' },
                      }}
                    >
                      <Link style={{ textDecoration: "none", color:"black"}} to="/NAŠE PRÁCE">
                        <MenuItem  onClick={handleClose}>NAŠE PRÁCE</MenuItem>
                      </Link>
                      <Link style={{ textDecoration: "none", color:"black"}} to="/KONTAKTY">
                        <MenuItem onClick={handleClose} >KONTAKTY</MenuItem>
                      </Link>
                      <Link style={{ textDecoration: "none", color:"black"}} to="/AUTODOPRAVA">
                        <MenuItem  onClick={handleClose}>AUTODOPRAVA</MenuItem>
                      </Link>
                      <Link style={{ textDecoration: "none", color:"black"}} to="/PŘEPRODEJ MATERIÁLU">
                        <MenuItem onClick={handleClose}>PŘEPRODEJ MATERIÁLU</MenuItem>
                      </Link>
                      <Link style={{ textDecoration: "none", color:"black"}} to="/STAVEBNÍ PRÁCE">
                        <MenuItem onClick={handleClose}>STAVEBNÍ PRÁCE</MenuItem>
                      </Link>
                    </Menu>
                </Box>

                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                  <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    onMouseEnter={handleClick}
                    sx={{ my: 2, color: 'black', display: 'block' }}
                  >
                    SLUŽBY
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                  <Link style={{ textDecoration: "none", color:"black"}} to="/AUTODOPRAVA">
                    <MenuItem  onClick={handleClose} onMouseEnter={handleButtonHover} onMouseLeave={handleButtonLeave}>AUTODOPRAVA</MenuItem>
                  </Link>
                  <Link style={{ textDecoration: "none", color:"black"}} to="/PŘEPRODEJ MATERIÁLU">
                    <MenuItem onClick={handleClose} onMouseEnter={handleButtonHover} onMouseLeave={handleButtonLeave}>PŘEPRODEJ MATERIÁLU</MenuItem>
                  </Link>
                  <Link style={{ textDecoration: "none", color:"black"}} to="/STAVEBNÍ PRÁCE">
                    <MenuItem onClick={handleClose} onMouseEnter={handleButtonHover} onMouseLeave={handleButtonLeave}>STAVEBNÍ PRÁCE</MenuItem>
                  </Link>
                </Menu>
                    {pages.map((page) => (
                      <Button
                        key={page}
                        onClick={handleClose}
                        sx={{ my: 2, color: 'black', display: 'block' }}
                      >
                        <Link style={{ textDecoration: "none", color:"black"}} to={`/${page}`}>{page}</Link>
                      </Button>
                    ))}
                    <Link>
                      <Button variant="contained" sx={{ mr: 2, color: "white",'&:hover': {backgroundColor: '#313131',}, backgroundColor: '#FF750E', borderRadius: 0, marginTop: 2, marginLeft: 2 }} onClick={handleOpenModal}>
                        ZADAT POPTÁVKU
                      </Button>
                      </Link>
                </Box>
              </Toolbar>
          </Container>
        </Box>
    </Box>
  );
}
export default NavBar;
